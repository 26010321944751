import styles from "./Content.module.css"
import { useEffect, useState } from "react"
import { getStarters, getDishes, getDesserts } from "../../sanity";
import { translate, add24Hours } from "../../translator";
import Loader from "../Loader/Loader";

const Content = ({name, price, changeLang}) => {

    const [content, setContents] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        (async() => {

            let res;
         
            if(name === "Desserts"){
                res = await getDesserts()
            } else if(name === "Plats"){
                res = await getDishes()
            } else {
                res = await getStarters()
            }

            // let update = false
            // const localDate = localStorage.getItem("limitDate")
            // const localData = localStorage.getItem("dataDesserts") || localStorage.getItem("dataPlats") || localStorage.getItem("dataStarters")
            // if(localDate && localData){
            //     if(new Date() > new Date(localDate)){
            //         update = true
            //     }
            // } else {
            //     update = true
            // }

            let update = true

            if(localStorage.getItem('lang') !== 'FR'){
                if(update){
                    setLoading(true)
                    const tab = await translate(res, localStorage.getItem('lang'))
                    if(name === "Desserts"){
                        localStorage.setItem("dataDesserts", JSON.stringify(tab))
                    } else if(name === "Plats"){
                        localStorage.setItem("dataPlats", JSON.stringify(tab))
                    } else {
                        localStorage.setItem("dataStarters", JSON.stringify(tab))
                    }
                    localStorage.setItem("limitDate", add24Hours())
                    setContents(tab)
                    setLoading(false)
                } else {
                    if(name === "Desserts"){
                        setContents(Array(JSON.parse(localStorage.getItem("dataDesserts")))[0])
                    } else if(name === "Plats"){
                        setContents(Array(JSON.parse(localStorage.getItem("dataPlats")))[0])
                    } else {
                        setContents(Array(JSON.parse(localStorage.getItem("dataStarters")))[0])
                    }
                }
            } else {
                setContents(res)
            }

        })()
    }, [changeLang])

    const getSectionName = (name) => {
        if(localStorage.getItem('lang') === "EN"){
            if(name === "Entrées") return "Starters"
            else if(name == "Plats") return "Dishes"
            else return "Desserts"
        }
        return name
    }

    return(
       <>
        {loading ?
            <Loader />
        :
            <div className={styles.content}>
                <h2>{getSectionName(name)} - {price} €</h2>
                {content.length > 0 && content.map((line, index) => (
                    <div key={index}>
                        <p>{line.name}</p>
                        {index !== content.length - 1 && <p className={styles.dot}>•</p>}
                    </div>
                ))}
            </div>
        }
       </>
    )
}

export default Content