import Header from "../../components/Header/Header";
import Menus from "../../components/Menus/Menus";
import Content from "../../components/Content/Content";
import { useState, useEffect } from "react";
import { getTypes } from "../../sanity";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";

const Home = () => {

    const [types, setTypes] = useState([])
    const [changeLang, setChangeLang] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async() => {
            setLoading(true)
            const types_fetched = await getTypes()
            setTypes(types_fetched)
            setLoading(false)
        })()
    }, [])


    return(
        <>
            {loading ? 
                 <Loader />
            :
                <>
                    <Header changeLang={changeLang} setChangeLang={setChangeLang} />
                    <Menus changeLang={changeLang} />
                    {types.length > 0 && types.map((type, index) => (
                        <Content key={index} name={type.name} price={type.price} changeLang={changeLang} />
                    ))}
                    <Footer />
                </>
            }
        </>
    )
}

export default Home;