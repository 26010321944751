import styles from "./Header.module.css"
import Lang from "../Lang/Lang";

const Header = ({changeLang, setChangeLang}) => {


    return(
        <div className={styles.header}>
            <img src="https://restaurantleradisbeurre.com/wp-content/uploads/2021/06/radis-beurre-logo-noir.png" alt="Logo" className={styles.logo} />
            <Lang changeLang={changeLang} setChangeLang={setChangeLang} />
        </div>
    )
}

export default Header;