import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styles from "./Loader.module.css"

const Loader = () => {
    return (
        <div className={styles.shadow}>
            <img src="https://restaurantleradisbeurre.com/wp-content/uploads/2021/06/radis-beurre-logo-noir.png" alt="Logo" className={styles.logo} />
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color="success" />
            </Box>
        </div>
    )
}

export default Loader