// sanity.js
import {createClient} from '@sanity/client'
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const client = createClient({
  projectId: process.env.REACT_APP_PROJECT_ID,
  dataset: process.env.REACT_APP_DATABASE_NAME,
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

// uses GROQ to query content: https://www.sanity.io/docs/groq
export const getMenus = async () => {
  return await client.fetch(`*[_type == "menu"] | order(order asc)`)
}

export const getTypes = async () => {
  return await client.fetch(`*[_type == "category"] | order(order asc)`)
}

export const getStarters = async () => {
  return await client.fetch(`*[_type == "starter"] | order(order asc)`)
}

export const getDishes = async () => {
  return await client.fetch(`*[_type == "plat"] | order(order asc)`)
}

export const getDesserts = async () => {
  return await client.fetch(`*[_type == "dessert"] | order(order asc)`)
}