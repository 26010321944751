import styles from "./Lang.module.css"
import { useState } from "react"
import clsx from 'clsx'

const Lang = ({changeLang, setChangeLang}) => {

    const [open, setOpen] = useState(false)
    const lang = localStorage.getItem('lang')
    const langs = [
        {flag: "GB"},
        {flag:"FR"},
        {flag:"ES"},
        {flag:"CN"},
        {flag:"KR"},
        {flag: "JP"},
        {flag: "DE"},
        {flag:"PT"},
        {flag: "IT"}
    ]

    const onChangeLang = (lang) => {
        setOpen(false)
        if(lang !== localStorage.getItem('lang')){
            localStorage.setItem('lang', lang);
            setChangeLang(!changeLang)
        }
    }

    const getFlagFromCodeLang = (code) => {
        for(let i=0; i<langs.length; i++){
            if(code.toUpperCase() === langs[i].lang) {
                return langs[i].flag;
            }
        }
    }

    return(
        <>
            {open && <div onClick={() => setOpen(false)} className={styles.shadow}></div>}
            <div className={styles.relativeOne}>
                <div className={clsx(styles.listed, styles.lang)} onClick={() => setOpen(!open)}>
                    <img className={styles.flag} src={`https://flagsapi.com/${lang}/flat/64.png`} alt="flag"></img>
                    <p className={styles.langText}>{lang}</p>
                    <p className={styles.toggle}>&#9660;</p>
                </div>
                <div className={styles.langs}>
                    {open && <div className={styles.popup}>
                        {langs.map((lang, index) => (
                            <div className={clsx(styles.listed, lang.lang === localStorage.getItem('lang') ? styles.selected : null)} onClick={() => onChangeLang(lang.flag)} key={index}>
                                <img className={styles.flag} src={`https://flagsapi.com/${lang.flag.toUpperCase()}/flat/64.png`} alt="flag"></img>
                                <p className={styles.langText}>{lang.flag}</p>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Lang