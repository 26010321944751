import { useEffect, useState } from "react"
import styles from "./Menus.module.css"
import { getMenus } from "../../sanity"
import { translate, add24Hours } from "../../translator"
import Loader from "../Loader/Loader"


const Menus = ({changeLang}) => {

    const [menus, setMenus] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        
        (async() => {

            const res = await getMenus()

            // let update = false
            // const localDate = localStorage.getItem("limitDateMenu")
            // const localData = localStorage.getItem("dataMenu")
            // if(localDate && localData){
            //     if(new Date() > new Date(localDate)){
            //         update = true
            //     }
            // } else {
            //     update = true
            // }

            let update = true

            if(localStorage.getItem('lang') !== 'FR'){
                if(update){
                    setLoading(true)
                    const tab = await translate(res, localStorage.getItem('lang'))
                    localStorage.setItem("dataMenu", JSON.stringify(tab))
                    localStorage.setItem("limitDateMenu", add24Hours())
                    setMenus(tab)
                    setLoading(false)
                } else {
                    setMenus(Array(JSON.parse(localStorage.getItem("dataMenu")))[0])
                }
            } else {
                setMenus(res)
            }

        })()

    }, [changeLang])


    return(
        <div className={styles.menus}>
            {!loading ?
                <>
                    <hr/>
                    {menus && menus.length > 0 && menus.map((menu, index) => (
                        <p key={index}>{menu.name} - <b>{menu.price}€</b></p>
                    ))}
                </>
            :
                <Loader />
            }
            <hr/>
        </div>
    )
}

export default Menus