import axios from "axios"
import OpenAI from "openai"

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true
});

const getCountryFromCode = (code) => {
    switch(code){
        case "GB":
            return "anglais"
        case "FR":
            return "français"
        case "ES":
            return "espagnol"
        case "CN":
            return "chinois mandarin"
        case "KR":
            return "coréen"
        case "JP":
            return "japonais"
        case "DE":
            return "allemand"
        case "IT":
            return "italien"
        default:
            return "portugais"
    }
}

export const translate = async (res, lang_dest) => {
    try{
        let tab = []
        for(let i=0; i<res.length; i++) {
           
            const response = await openai.chat.completions.create({
                messages: [{ role: 'user', content: `Tu dois traduire une ligne d'un menu d'un restaurant. Attention il faut que tu traduises de manière rigoureuse dans le domaine de la gastronomie. Pense bien à te placer dans la peau d'un chef cuisinier pour traduire correctement. Traduis moi en ${getCountryFromCode(lang_dest)} la ligne suivante : ${res[i].name}. Renvoie uniquement la traduction.` }],
                model: 'gpt-3.5-turbo',
            });

            tab.push({name: response.choices[0].message.content, price: res[i].price})

        }
        return tab
    } catch(e){
        localStorage.setItem('lang', 'FR')
        console.log(e)
        window.location.href = '/'
    }
}

export const add24Hours = () => {
    var newDate = new Date();
    var futureDateObj = new Date(newDate.getTime() + (24 * 60 * 60 * 1000));
    var futureDateString = futureDateObj.toISOString();
    return futureDateString
}